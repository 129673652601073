import MyErrorBoundary from "Component/MyErrorBoundary";
import Layout from "Layout";
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = (prop: any = {}) => {
  const { component: Component } = prop;

  return (
    <React.Fragment>
      {sessionStorage.getItem("userLoginData") ? (
        <React.Fragment>
          <MyErrorBoundary>
            <Layout>
              <Component />
            </Layout>
          </MyErrorBoundary>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MyErrorBoundary>
            <Navigate to="/" />
          </MyErrorBoundary>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default React.memo(ProtectedRoute);
