import React from "react";
import { makeStyles, Tab, TabList } from "FluentUiElements/FluentUiProvider";
import { SelectTabData, SelectTabEvent, TabValue } from "FluentUiElements/FluentUiProvider/types";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px"
  }
});

type TablistFUiProps = {
  tablListArray?: Array<any>;
  getTabValue?: (e?: any) => void;
};
const TablistFUi = (props: TablistFUiProps) => {
  const styles = useStyles();

  const { tablListArray = [], getTabValue = () => {} } = props;
  const [selectedValue, setSelectedValue] = React.useState<TabValue>("tab0");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    getTabValue(data.value);
  };

  return (
    <div style={{ borderBottom: "1px solid rgb(229, 231, 235)" }} className={styles.root}>
      <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} defaultSelectedValue="tab0">
        {tablListArray.map((obj, index) => {
          return (
            <>
              <Tab style={{ minWidth: "80px" }} value={`tab${index}`}>
                {obj}
              </Tab>
            </>
          );
        })}
      </TabList>
    </div>
  );
};
export default TablistFUi;
