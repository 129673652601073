import { secretKeyData } from "apiManage";
import CryptoJS from "crypto-js";

/**
 * @param  {} storeName=""
 * @param  {any} value
 * @param  {boolean} fromPassPage=false // from pass word page only not string
 */
export const encryptData = (storeName = "", value: any = "") => {
  try {
    const payload = typeof value === "string" ? value : JSON.stringify(value);
    const cipherlogUser: any = CryptoJS.AES.encrypt(payload, secretKeyData as any);
    sessionStorage.setItem(storeName, cipherlogUser);
    return true;
  } catch (e) {
    return null;
  }
};

/**
 * encrypt value decrypt Here
 */
export const Decrypt = (storeName = "") => {
  if (sessionStorage.getItem(storeName) !== null) {
    try {
      const ciphertext: any = sessionStorage.getItem(storeName);
      const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKeyData as any);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
      return null;
    }
  }
  return null;
};

// /**
//  * @param  {any} value
//  * payload params encrypt
//  */
// export const dataEncrypt = (value: any) => {
//     const cipherlogUser: any = CryptoJS.AES.encrypt(JSON.stringify(value), secretKeyData as any);
//     return cipherlogUser;
// };
