import React from "react";

const Home = React.lazy(() => import("Pages/PrivateCompoenet/Home"));
const Login = React.lazy(() => import("Pages/PublicComponent/Login"));
const Report = React.lazy(() => import("Pages/PrivateCompoenet/Report"));
const Settings = React.lazy(() => import("Pages/PrivateCompoenet/Settings"));
const Register = React.lazy(() => import("Pages/PublicComponent/Register"));
const Dasboard = React.lazy(() => import("Pages/PrivateCompoenet/Dasboard"));
const Incentives = React.lazy(() => import("Pages/PrivateCompoenet/Incentives"));
const UsersRoles = React.lazy(() => import("Pages/PrivateCompoenet/UsersRoles"));
const PageNotFound = React.lazy(() => import("Pages/PublicComponent/PageNotFound"));
const MopIncentive = React.lazy(() => import("Pages/PrivateCompoenet/MopIncentive"));
const ForgetPassword = React.lazy(() => import("Pages/PublicComponent/ForgetPassword"));
const CreatePassword = React.lazy(() => import("Pages/PublicComponent/CreatePassword"));
const TargetIncentive = React.lazy(() => import("Pages/PrivateCompoenet/TargetIncentive"));
const MopIncentiveSplit = React.lazy(() => import("Pages/PrivateCompoenet/MopIncentiveSplit"));
const MopIncentiveCreate = React.lazy(() => import("Pages/PrivateCompoenet/MopIncentive/MopIncentiveCreate"));

export {
  Login,
  Home,
  Report,
  Register,
  Dasboard,
  Settings,
  Incentives,
  UsersRoles,
  MopIncentive,
  PageNotFound,
  CreatePassword,
  ForgetPassword,
  TargetIncentive,
  MopIncentiveSplit,
  MopIncentiveCreate
};
