import styled from '@emotion/styled';

// eslint-disable-next-line template-tag-spacing
export const StyledBackdrop = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  background-color: rgb(0, 0, 0, 0.3);
`;
