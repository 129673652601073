import "./sidebar.css";
import React, { useEffect, useState } from "react";
import { Global } from "./icons/Global";
import { Diamond } from "./icons/Diamond";
import { BarChart } from "./icons/BarChart";
import { InkBottle } from "./icons/InkBottle";
import { useLocation } from "react-router-dom";
import { ShoppingCart } from "./icons/ShoppingCart";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from "../SideBar";
import { IconCurrencyDollar, IconFile, IconLayout, IconMeeting, IconSettings } from "assets/img";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#565E6C"
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#F4F1FD",
        color: "#565E6C"
      },
      disabled: {
        color: "#9fb6cf"
      }
    }
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7"
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9"
      },
      disabled: {
        color: "#3e5e7e"
      }
    }
  }
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SideBar: React.FC = () => {
  const tempHide = false;
  const { pathname = "" } = useLocation();
  // const { location: { pathname = "/dasboard" } = {} } = History;
  const [getPathName, setPathName] = useState("/mop-incentive");
  const collapsed = false;
  const rtl = false;
  const hasImage = false;
  const theme = "light";
  const [toggled, setToggled] = React.useState(false);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      }
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9"
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1) : "transparent"
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color
      }
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined
    })
  };

  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  return (
    <div
      style={{
        display: "flex",
        direction: rtl ? "rtl" : "ltr"
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{
          color: themes[theme].sidebar.color
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu menuItemStyles={menuItemStyles}>
              {tempHide && (
                <>
                  {" "}
                  <MenuItem active={getPathName === "/dasboard"} href="/dasboard" icon={<IconLayout />}>
                    Dasboard
                  </MenuItem>
                  <MenuItem active={getPathName === "/incentives"} href="/incentives" icon={<IconCurrencyDollar />}>
                    Incentives
                  </MenuItem>{" "}
                </>
              )}
              {tempHide && (
                <>
                  {" "}
                  <MenuItem active={getPathName === "/report"} href="/report" icon={<IconFile />}>
                    Report
                  </MenuItem>
                  <MenuItem active={getPathName === "/settings"} href="/settings" icon={<IconSettings />}>
                    Settings
                  </MenuItem>{" "}
                </>
              )}
              <MenuItem active={getPathName === "/mop-incentive"} href="/mop-incentive" icon={<IconSettings />}>
                MOP Item Incentive
              </MenuItem>
              <MenuItem
                active={getPathName === "/mop-incentive-split"}
                href="/mop-incentive-split"
                icon={<IconSettings />}
              >
                MOP Incentive Split
              </MenuItem>

              {tempHide && (
                <>
                  {" "}
                  <MenuItem active={getPathName === "/users-roles"} href="/users-roles" icon={<IconMeeting />}>
                    Users and Roles
                  </MenuItem>{" "}
                  <MenuItem
                    active={getPathName === "/target-incentive"}
                    href="/target-incentive"
                    icon={<IconSettings />}
                  >
                    Target Incentive
                  </MenuItem>
                  <SubMenu className="hidden" label="Charts" icon={<BarChart />}>
                    <MenuItem> Pie charts</MenuItem>
                    <MenuItem> Line charts</MenuItem>
                    <MenuItem> Bar charts</MenuItem>
                  </SubMenu>{" "}
                </>
              )}

              {tempHide && (
                <>
                  {" "}
                  <SubMenu label="Maps" icon={<Global />}>
                    <MenuItem> Google maps</MenuItem>
                    <MenuItem> Open street maps</MenuItem>
                  </SubMenu>
                  <SubMenu label="Theme" icon={<InkBottle />}>
                    <MenuItem> Dark</MenuItem>
                    <MenuItem> Light</MenuItem>
                  </SubMenu>
                  <SubMenu label="Components" icon={<Diamond />}>
                    <MenuItem> Grid</MenuItem>
                    <MenuItem> Layout</MenuItem>
                    <SubMenu label="Forms">
                      <MenuItem> Input</MenuItem>
                      <MenuItem> Select</MenuItem>
                      <SubMenu label="More">
                        <MenuItem> CheckBox</MenuItem>
                        <MenuItem> Radio</MenuItem>
                      </SubMenu>
                    </SubMenu>
                  </SubMenu>
                  <SubMenu label="E-commerce" icon={<ShoppingCart />}>
                    <MenuItem> Product</MenuItem>
                    <MenuItem> Orders</MenuItem>
                    <MenuItem> Credit card</MenuItem>
                  </SubMenu>{" "}
                </>
              )}
            </Menu>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};
