import { makeStyles, tokens } from "FluentUiElements/FluentUiProvider";

export const _useStyles = makeStyles({
  btnRed: {
    backgroundColor: tokens.colorPaletteRedBackground3,
    color: "#fff",
    ":hover": {
      backgroundColor: tokens.colorPaletteRedForeground1,
      color: "#fff"
    },
    ":active": {
      backgroundColor: tokens.colorPaletteRedForeground1,
      color: "#fff"
    }
  },
  btnPrimary: {
    backgroundColor: " #754FE9",
    color: "#fff",
    ":hover": { backgroundColor: "#5030b4" },
    ":active": { backgroundColor: "#5030b4" }
  },
  btnPrimaryLite: {
    backgroundColor: " #D4C8F8",
    color: "#754FE9",
    ":hover": { backgroundColor: "#754FE9", color: "#fff" },
    ":active": { backgroundColor: "#754FE9", color: "#fff" }
  }
});
