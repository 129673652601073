import { createLightTheme, createDarkTheme } from "FluentUiElements/FluentUiProvider";
import { Theme, BrandVariants } from "FluentUiElements/FluentUiProvider/types";

const purple: BrandVariants = {
  10: "#030206",
  20: "#1A1327",
  30: "#291D46",
  40: "#362660",
  50: "#432E7C",
  60: "#513799",
  70: "#5E40B7",
  80: "#6C49D6",
  90: "#7C56EB",
  100: "#8C67ED",
  110: "#9C78F0",
  120: "#AA89F3",
  130: "#B89AF5",
  140: "#C5ABF7",
  150: "#D2BDF9",
  160: "#DFCFFB"
};

export const lightTheme: Theme = {
  ...createLightTheme(purple)
};
export const darkTheme: Theme = {
  ...createDarkTheme(purple)
};
