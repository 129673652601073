import { _get } from "utils/lodashConst";

export const initState = {
  isLogin: false,
  coordsData: {}
};

export const homePageReducer = (state = initState, action: any = {}) => {
  switch (_get(action, "type", "")) {
    case "MOB_INCENTIVE_INITIAL_STAGE": {
      return {
        ...state,
        ...initState
      };
    }

    case "LOGIN_CORDS_LAT_LONG_DETAILS": {
      return {
        ...state,
        coordsData: action.coordsData
      };
    }

    case "MOB_INCENTIVE_LOADER": {
      return {
        ...state,
        loginLoader: action.loginLoader
      };
    }
    default:
      return {
        ...state
      };
  }
};
