import { DatePickerProps } from "FluentUiElements/FluentUiProvider/types";
import { Field, makeStyles, DatePicker } from "FluentUiElements/FluentUiProvider";

const useStyles = makeStyles({
  control: {
    maxWidth: "300px"
  }
});

type labelProps = {
  label?: string;
};

const DatePickerFUi = (props: Partial<DatePickerProps> & labelProps) => {
  const styles = useStyles();

  const { label = "Select a date", placeholder = "Select a date..." } = props;

  return (
    <Field label={label}>
      <DatePicker className={styles.control} placeholder={placeholder} {...props} style={{ minWidth: "150px" }} />
    </Field>
  );
};
export default DatePickerFUi;
