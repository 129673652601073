import { Button } from "FluentUiElements/FluentUiProvider";

type ButtonFUiType = {
  type?: any;
  icon?: any;
  style?: any;
  shape?: any;
  size?: any;
  color?: string;
  onClick?: any;
  children?: any;
  className?: any;
  appearance?: any;
};
const ButtonFUi = (props: ButtonFUiType) => {
  const {
    size = "",
    children,
    style = {},
    className = "",
    type = "button",
    onClick = () => {},
    appearance = "primary"
  } = props;
  return (
    <Button
      type={type}
      size={size}
      {...props}
      onClick={onClick}
      style={{ ...style }}
      className={className}
      appearance={appearance}
    >
      {children}
    </Button>
  );
};
export default ButtonFUi;
