import React, { Suspense } from "react";
import Loader from "Component/Loader";
import ProtectedRoute from "./ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import {
  Login,
  Report,
  Register,
  Settings,
  Dasboard,
  UsersRoles,
  Incentives,
  PageNotFound,
  MopIncentive,
  CreatePassword,
  ForgetPassword,
  TargetIncentive,
  MopIncentiveCreate,
  MopIncentiveSplit
} from "./ComponentPage";

const ScreenRouter = () => {
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/dasboard/:token?"
            element={<ProtectedRoute component={Dasboard} path="/dasboard/:token?" componentName={"Dasboard"} />}
          />
          <Route
            path="/incentives/:token?"
            element={<ProtectedRoute component={Incentives} path="/incentives/:token?" componentName={"Incentives"} />}
          />
          <Route
            path="/mop-incentive/:token?"
            element={
              <ProtectedRoute component={MopIncentive} path="/mop-incentive/:token?" componentName={"MopIncentive"} />
            }
          />
          <Route
            path="/mop-incentive-split/:token?"
            element={
              <ProtectedRoute
                component={MopIncentiveSplit}
                path="/mop-incentive-split/:token?"
                componentName={"MopIncentiveSplit"}
              />
            }
          />
          <Route
            path="/mop-incentive-create/:token?"
            element={
              <ProtectedRoute
                component={MopIncentiveCreate}
                path="/mop-incentive-create/:token?"
                componentName={"MopIncentiveCreate"}
              />
            }
          />
          <Route
            path="/report/:token?"
            element={<ProtectedRoute component={Report} path="/report/:token?" componentName={"Report"} />}
          />
          <Route
            path="/settings/:token?"
            element={<ProtectedRoute component={Settings} path="/settings/:token?" componentName={"Settings"} />}
          />
          <Route
            path="/target-incentive/:token?"
            element={
              <ProtectedRoute
                component={TargetIncentive}
                path="/target-incentive/:token?"
                componentName={"TargetIncentive"}
              />
            }
          />
          <Route
            path="/users-roles/:token?"
            element={<ProtectedRoute component={UsersRoles} path="/users-roles/:token?" componentName={"UsersRoles"} />}
          />

          <Route path="/" element={<Login />} />
          <Route path="/not-found" element={<PageNotFound />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/reset-password/:token?" element={<CreatePassword />} />
          <Route path="/register/:token?" element={<Register />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* <IdleTimeOut /> */}
        {/* <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="gigs" element={<ForgetPassword />} />
                </Routes> */}
      </Suspense>
    </React.Fragment>
  );
};

export default React.memo(ScreenRouter);
