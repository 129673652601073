export const envPath = process.env.REACT_APP_ENV; //env oc3 pincode
export const secretKeyData = process.env.REACT_APP_SECRET_KEY; //secretKEY for decrypt and encrypt
export const salesApiBaseUrl = process.env.REACT_APP_SALES_APP_BASE_URL; //base path

export const staticData = {
  pinCode: 6,
  MobileNo: 10,
  imeilength: 30,
  thirtyMbHandle: 31457280
};

/**
 * @param  {object} params;
 * Api request params convert to queryParams
 */
export const encodeGetParams = (params: any = {}) => {
  return Object.entries(params)
    .map((key: any) =>
      key
        .map((k: any) => {
          const enCodeData = typeof k === "object" ? JSON.stringify(k) : k;
          return encodeURIComponent(enCodeData);
        })
        .join("=")
    )
    .join("&");
};

export const loginApi = {
  loginPage: "/api/v1/auth/login" //url shorter call
};

export const mobIncentiveApi = {
  getMobDetailsIncentive: "/api/v1/incentive",
  getMobDetailsIncentiveSplit: "/api/v1/incentive-split",
  sampleFileDownload: "/api/v1/incentive/format/download",
  getMobDetailsIncentiveUpload: "/api/v1/incentive/upload"
};
