import { Image } from "FluentUiElements/FluentUiProvider";

type ImageFUiProps = {
  src: string;
  alt: string;
  style?: any;
  className?: string;
};
const ImageFUi = (props: ImageFUiProps) => {
  const { src = "", alt = "", className = "", style = {} } = props;
  return <Image alt={alt} src={src} className={className} style={{ ...style }} {...props} />;
};
export default ImageFUi;
