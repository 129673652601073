import React from "react";
import "./assets/styles/common.css";
import "./assets/styles/utility.css";
import Loader from "Component/Loader";
import Routing from "Component/Routing";
import { useSelector } from "react-redux";
import NetworkDetector from "Component/NetworkDetector";
import ToastContainer from "Component/ToastServices/ToastContainer";

export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

function App() {
  const globalState = useSelector((state: any) => state);
  const {
    commonReducer: { isLoading = false }
  } = globalState;
  return (
    <React.Fragment>
      <Routing />
      <ToastContainer />
      <NetworkDetector />
      {isLoading ? <Loader /> : null}
    </React.Fragment>
  );
}

export default App;
