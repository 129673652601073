import React from "react";
import { Navigate } from "react-router-dom";
import { ImgLogoNav } from "assets/brandings";
import { ButtonFUi, ImageFUi, TextFUi } from "FluentUiElements";
import { SignOutRegular } from "@fluentui/react-icons";
import { _useStyles } from "FluentUiElements/Theming";
import { Text } from "FluentUiElements/FluentUiProvider";
import { moveToLogOut } from "utils/helper";

function LayoutHeader() {
  const classes = _useStyles();
  const handleLogout = () => {
    moveToLogOut();
  };

  if (!window.sessionStorage.getItem("userLoginData")) {
    return <Navigate to="/" />;
  }
  return (
    <div className="layout_header">
      <div className="flex justify-between items-center p-3">
        <a href="/" className="w-8 h-7 flex items-center gap-3">
          <ImageFUi src={ImgLogoNav} alt="logo" className="block w-full h-full object-contain" />
          <TextFUi as="strong" size={400} weight="semibold">
            Incentive
          </TextFUi>
        </a>
        <div className="">
          <ButtonFUi
            shape="rounded"
            size="medium"
            appearance="primary"
            icon={<SignOutRegular />}
            onClick={handleLogout}
            className={classes.btnPrimary}
          >
            <Text wrap={false}>Logout</Text>
          </ButtonFUi>
        </div>
      </div>
    </div>
  );
}

export default LayoutHeader;
