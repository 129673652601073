import React from "react";

type LayoutBodyProps = {
  children?: any;
};

function LayoutBody(props: LayoutBodyProps) {
  const { children = "" } = props;
  return (
    <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 60px)" }} className="flex-1 layout_body p-5 h-full">
      {children}
    </div>
  );
}

export default LayoutBody;
