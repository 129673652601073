import { makeStyles, shorthands, useId, Input, Label } from "FluentUiElements/FluentUiProvider";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "100%",
    width: "100%"
  }
});

interface InputFUiProps {
  size?: any;
  type?: any;
  style?: any;
  name: string;
  id?: string;
  value: string;
  label?: string;
  onInput?: any;
  errorMsg?: string;
  maxLength?: number;
  className?: string;
  disabled?: boolean;
  contentBefore?: any;
  contentAfter?: any;
  placeholder?: string;
  onChange?: (e?: any) => void;
}

const InputFUi = (props: InputFUiProps) => {
  const {
    onInput,
    type = "",
    name = "",
    value = "",
    label = "",
    contentAfter,
    errorMsg = "",
    contentBefore,
    className = "",
    size = "medium",
    placeholder = "",
    disabled = false,
    onChange = () => {},
    maxLength = 1000000
  } = props;

  const inputId = useId("input");
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {label ? (
        <Label weight="semibold" htmlFor={inputId} size={size} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <Input
        type={type}
        name={name}
        id={inputId}
        value={value}
        autoComplete="off"
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
        className={className}
        placeholder={placeholder}
        contentAfter={contentAfter}
        contentBefore={contentBefore}
        onInput={onInput ? onInput : () => {}}
      />

      {errorMsg ? (
        <div className="h-5">
          <div className="text-red-500 text-xs">{errorMsg}</div>
        </div>
      ) : null}
    </div>
  );
};
export default InputFUi;
