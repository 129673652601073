import styled, { CSSObject } from "@emotion/styled";

export const tempHide = false;

export const moveToLogOut = () => {
  sessionStorage.clear();
  window.location.reload();
};

interface StyledMenuLabelProps {
  rootStyles?: CSSObject;
}

// eslint-disable-next-line template-tag-spacing
export const StyledPrimaryBtn = styled.span<StyledMenuLabelProps>`
  background: #754fe9;
  color: #ffffff;

  ${({ rootStyles }) => rootStyles};
`;

/**
 * convert title case
 * @param string
 */
export const convertToTitleCase = (convertValue: any = "") => {
  if (convertValue) {
    return convertValue?.toLowerCase()?.replace(/(?:^|\s|-)\w/g, function (match: any) {
      return match?.toUpperCase();
    });
  }
  return "";
};

/**
 * convert lower case
 * @param string
 */
export const convertToLowerCase = (convertValue: any = "") => {
  return convertValue ? ("" + convertValue).toLowerCase() : "";
};

/**
 * convert string
 * @param string
 */
export const convertToString = (convertValue: any = "") => {
  return convertValue ? "" + convertValue : "";
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToObj = (obj: any = null) => {
  if (obj) {
    return typeof obj === "object" ? obj : {};
  }
  return {};
};

/**
 * check is null or string
 * @param str
 */
export const nullToString = (str: any = null) => {
  if (str) {
    return typeof str === "string" ? str : "";
  }
  return "";
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToArray = (array: any = null) => {
  return Array.isArray(array) ? array : [];
};

/**
 * @param str string
 * @returns remove all white space
 */
export const removeWhiteSpace = (str) => {
  if ("" + str) {
    return str.replace(/ /g, "");
  }
  return "";
};

/**
 * dateBased time since
 */
export const timeSince = (inData: Date) => {
  if (inData) {
    const date = new Date(inData);
    const curDate = new Date();
    const seconds = Math.floor((curDate.getTime() - date.getTime()) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.round(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.round(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.round(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.round(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.round(interval) + " minutes ago";
    }
    const secTime = Math.round(seconds);
    return (secTime > 0 ? secTime : 0) + " seconds ago";
  }
  return "";
};

/**
 * @param  {string} value=""
 * enter value has been change upperCase
 * first letter convert to upperCase
 */
export const firstLetterUpperCase = (value = "") => {
  if (value) {
    return convertToString(value)
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return "";
};

/**
 * @param  {string} value=""
 * @param  {string} stringLength=1000 Default value is 1000
 * text length is moreThen fixed place disPlay like ...
 */
export const trimCharacters = (value: any = "", stringLength = 100000000) => {
  if (nullToString(value) !== "") {
    return convertToString(value).length > stringLength ? value.substring(0, stringLength).concat("...") : value;
  }
  return "";
};

/**
 * convert to milli sec
 * @param dateString string
 * @returns milli sec
 */
export const convertUTCToLocalTime = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  }
  return 0;
};

/**
 *
 * @param millis string
 * @returns
 */
export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (+seconds < 10 ? "0" : "") + seconds;
}

/**
 *
 * @param fn function
 * @param ms number
 * @returns function
 */
export const debounceTime = (fn: any, ms = 300) => {
  let timeOut;
  return function (this: any, ...arg: any[]) {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => fn.apply(this, arg), ms);
  };
};

/**
 *
 * @param number string
 * @returns last ten number splice
 */
export const mobileNumberSplice = (number = "") => {
  return nullToString(number).slice(-10);
};

/**
 * @param  {Array} event
 * upload size handle
 */
export const fileUploadSizeValidate = (size: any = 0, uploadSize = 0) => {
  if (size < uploadSize) {
    return true;
  }
  return false;
};

/**
 * @param value excel validation
 */
export const excelFileValidate = (value: any = "") => {
  const allowFormet = ["csv", "xlsx"];
  const extension: any = value.split(".").pop();
  return allowFormet.includes(extension);
};

export const manualDownloadUrlBased = (url = "", name = "") => {
  const link = document && document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document && document.body.appendChild(link);
  link.click();
};
