import { _get } from "utils/lodashConst";

export const initState = {
  isLoading: false
};

export const commonReducer = (state = initState, action: any = {}) => {
  switch (_get(action, "type", "")) {
    case "PAGE_LOADER": {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }

    case "PAGE_LOADER2": {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }
    default:
      return {
        ...state
      };
  }
};
