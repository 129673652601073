import * as React from "react";

import { Button, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover } from "FluentUiElements/FluentUiProvider";

const MenuFUi = () => (
  <Menu>
    <MenuTrigger disableButtonEnhancement>
      <Button>Toggle menu</Button>
    </MenuTrigger>

    <MenuPopover>
      <MenuList>
        <MenuItem>New </MenuItem>
        <MenuItem>New Window</MenuItem>
        <MenuItem disabled>Open File</MenuItem>
        <MenuItem>Open Folder</MenuItem>
      </MenuList>
    </MenuPopover>
  </Menu>
);

export default MenuFUi;
