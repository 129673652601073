import React from "react";
import "./layout.css";
import LayoutHeader from "./LayoutHeader";
import LayoutAside from "./LayoutAside";
import LayoutBody from "./LayoutBody";

type LayoutProps = {
  children?: any;
};

function Layout(props: LayoutProps) {
  const { children = "" } = props;

  return (
    <div style={{ height: "100vh" }} className="layout_parent flex flex-col max-h-full ">
      <LayoutHeader />
      <div className="flex h-full">
        <LayoutAside />
        <LayoutBody>{children}</LayoutBody>
      </div>
    </div>
  );
}

export default Layout;
